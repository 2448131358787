import React, { useState, useEffect } from "react"
import Layout from "./../components/Layout"
import FoireAuxQuestions from "./../nav/FAQ"
import { Helmet } from "react-helmet"
import { getUser } from "./../auth"

const FAQPage = () => {
  const [user, setUser] = useState(undefined)
  useEffect(() => {
    getUser(setUser)
  }, [])

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | FAQ</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-25 ans. Sur cette page,
          tu trouveras les réponses à tes questions à propos de WYNIT."
        />
        <meta
          name="keywords"
          content="bons plans,marseille,réductions,jeunes,questions,réponses"
        />
        <link rel="canonical" href="https://wynit.com/faq" />
      </Helmet>
      <FoireAuxQuestions />
    </Layout>
  )
}

export default FAQPage
