// extracted by mini-css-extract-plugin
export var HelpContent = "FAQ-module--HelpContent--+ExO+";
export var HelpListWrapper = "FAQ-module--HelpListWrapper--0LTlZ";
export var HelpWrapper = "FAQ-module--HelpWrapper--9DnU-";
export var HelpWrapperDeployed = "FAQ-module--HelpWrapperDeployed--B054r";
export var OrangeText = "FAQ-module--OrangeText--BVMtH";
export var PlusWrapper = "FAQ-module--PlusWrapper--wE8cW";
export var Spacer = "FAQ-module--Spacer---esDb";
export var TitleAndIconWrapper = "FAQ-module--TitleAndIconWrapper--taIVK";
export var WhiteText = "FAQ-module--WhiteText--xIODQ";
export var Wrapper = "FAQ-module--Wrapper--BnHYy";